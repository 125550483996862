class SmoothScroll {
  constructor(item){
    this.item = item;
    this.bindClick()
  }

  bindClick(){
    let scrollTo = this.item.getAttribute("href") ? this.item.getAttribute("href") : this.item.dataset.href;

    this.item.addEventListener('click', (event) => {
      event.preventDefault();
      let element = document.getElementById(scrollTo.substring(1));
      if (element) {
        window.scrollBy({
          top:  element.getBoundingClientRect().top - 50,
          behavior: 'smooth'
        })
      } else {
        console.log('There is no targets on the page.')
      }
    })
  }
}

let linkItems = document.querySelectorAll('.js-goTo');
if (linkItems) {
  [...linkItems].forEach(item => new SmoothScroll(item));
}
