//
// const sections = [].slice.call(document.querySelectorAll(".rhythmic__svg"));
//
// function createObserver(el) {
//   let observer;
//
//   const options = {
//     root: null,
//     rootMargin: "0px",
//     threshold: 0.5
//   };
//
//   observer = new IntersectionObserver(handleIntersect, options);
//   observer.observe(el);
// }
//
// function handleIntersect(entries, observer) {
//   entries.forEach((entry) => {
//     let box = entry.target;
//     let visible = entry.intersectionRatio;
//     if(visible > 0.5) {
//       box.classList.add('activeAnimate');
//     } else {
//       box.classList.remove('activeAnimate');
//     }
//   });
// }
//
//
//
// const setup = (sections) => {
//   for (let i in sections) {
//     const el = sections[i];
//     createObserver(el);
//   }
// }
//
// setup(sections);
//


