// FORM VALIDATION
class Validation {
	constructor(form) {
		this.form = form;
		this.parents = [...form.querySelectorAll('.field')];
		this.hiddens = [...form.querySelectorAll('input[type="hidden"]')];
		this.init()
	}

	init() {
		this.groups = this.parents.map(parent => {
			let input = parent.querySelector('input, textarea');
			let type = (parent.dataset.type) ? parent.dataset.type : input.tagName === 'TEXTAREA' ? 'textarea' :  input.getAttribute('type');
			return {parent, input, type, valid: false}
		});

		this.groups.forEach(group => this.validation(group));

		this.form.addEventListener('submit', (event) => {this.formAction.call(this, event)});
	}

	formAction(event) {
		event.preventDefault();
		let formData = new FormData;
		if (this.groups.every(item => item.valid) && this.groups.some(item => item.input.value.length)) {

			this.groups.filter(item => item.input.value).forEach(item => {
				formData.append(item.input.name, item.input.value)
			});

			this.hiddens.forEach(input => formData.append(input.name, input.value));

			fetch(this.form.getAttribute('action'), {
				method: 'POST',
				body: formData
			}).then(res => {
				if (res.ok) {
					const popup = document.querySelector('#thanks-popup');
					const cross = popup.querySelector('.popup__cross');

					popup.style.display = "flex";
					cross.onclick = function() {
						popup.style.display = "none";
					};

					this.groups.forEach(group => {
						group.input.value = '';
						group.input.removeAttribute('invalid');
					});
				} else {
					const popup = document.querySelector('#bad-popup');
					const cross = popup.querySelector('.popup__cross');

					popup.style.display = "flex";
					cross.onclick = function() {
						popup.style.display = "none";
					};
				}
			});
		} else {
			this.groups.forEach(group => {
				if (!group.valid)
					group.input.setAttribute('invalid', 'invalid')
			});
		}
	}

	validation(group) {
		switch (group.type) {
			case 'username':
				this.regex(group, /^[A-Za-zА-Яа-яЁё_-іІїЇєЄґ\s]{2,}$/)
				break;

			case 'city':
				this.regex(group, /^[A-Za-zА-Яа-яЁё_-іІїЇєЄґ\S\s]{3,}$/)
				break;

			case 'phone':
				// INPUT MASK
				let patternMask  = new IMask(group.input, {
					mask: '+{38} (000)-000-00-00',
					lazy: true
				});

				group.input.addEventListener('focus', function() {
					patternMask.updateOptions({ lazy: false });
				}, true);

				group.input.addEventListener('blur', function() {
					patternMask.updateOptions({ lazy: true });
				}, true);

				this.regex(group, /[\+][3][8]\s[\(]\d{3}[\)][/-]\d{3}[\-]\d{2}[\-]\d{2}$/)
				break;

			case 'email':
				this.regex(group, /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)
				break;
			case 'digit':
				this.regex(group, /^\d+$/)
				break;
			case 'checkbox':
				group.input.addEventListener('change', () => {this.checkboxCheck(group)});
				break;

			default:
				break;
		}
	}

	checkboxCheck(group) {
		if (group.input.checked) {
			group.input.removeAttribute('invalid');
			group.valid = true;
		} else {
			group.input.setAttribute('invalid', 'invalid');
			group.valid = false;
		}
	}

	regex(group, regex) {
		const validation = () => {
			if (regex.test(group.input.value)) {
				group.input.removeAttribute('invalid');
				group.valid = true;
			} else {
				group.input.setAttribute('invalid', 'invalid');
				group.valid = false;
			}
		};

		group.input.onblur = () => {
			validation.call(this);
			group.input.oninput = () => validation.call(this);
		}
	}
}

[...document.querySelectorAll('form')].forEach(form => {
	if (form) new Validation(form)
});