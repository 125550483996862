const btnRegion = document.querySelector('.header__current-region');
const menu = document.querySelector('.header__cities');
const toggleMenu = function() {
  menu.classList.toggle('visible');
}

btnRegion.addEventListener('click', function(e) {
  e.stopPropagation();
  toggleMenu();
});

document.addEventListener('click', function(e) {
  const target = e.target;
  const its_menu = target == menu || menu.contains(target);
  const its_btnMenu = target == btnRegion;
  const menu_is_active = menu.classList.contains('visible');

  if (!its_menu && !its_btnMenu && menu_is_active) {
    toggleMenu();
  }
});