// BANNER CAROUSEL
setTimeout(function() {
	const bannerSliderClassName = '.js-promo';

	if (document.querySelector(bannerSliderClassName)) {
		var bannerSlider = tns({
			container: bannerSliderClassName,
			items: 1,
			slideBy: 'page',
			mouseDrag: true,
			controls: false,
			swipeAngle: false,
			nav: true,
			navPosition: 'bottom',
			speed: 400,
			loop: true,
			// autoplay: true,
			// autoplayTimeout: 3000,
			// autoplayHoverPause: true,
			autoplayButtonOutput: false
		});
	}

	const tariffsSliderClassName = '.js-rates';

	if (document.querySelector(tariffsSliderClassName)) {
		var ratesSlider = tns({
			container: tariffsSliderClassName,
			controls: false,
			loop: false,
			swipeAngle: false,
			speed: 400,
			items: 1,
			gutter: 15,
			mouseDrag: false,
			navPosition: 'bottom',
			responsive: {
				500: {
					items: 2.5,
					gutter: 15,
					nav: true
				},
				1100: {
					items: 5,
					mouseDrag: false,
					nav: false,
					gutter: 30
				}
			}
		});
	}
}, 100);

class Rhythmic {
	constructor(item) {
		this.item = item;
		this.lines = [...this.item.querySelectorAll('.rhythmic__holder')];
		this.lines.forEach(function (path) {
			path.style.setProperty('stroke-dashoffset', path.getTotalLength());
			path.style.setProperty('stroke-dasharray', path.getTotalLength() / 2);
		})
	}
}

[...document.querySelectorAll(".rhythmic")].forEach(svg =>{new Rhythmic(svg)});


