class Tabs{
  constructor(item){
    this.parent = item;
    this.button = [...item.querySelectorAll('[data-filter]')];
    this.channels = [...item.querySelectorAll('.tarif-channels__item')];

    this.button.forEach(button => {
      button.addEventListener('click', () => {
        let filter = button.dataset.filter;

        this.channels.forEach(channel => {
          let categories = channel.dataset.cat.split(' ');

          channel.style.display = "none";
          if (categories.indexOf(filter) !== -1 || !filter) channel.style.display = "block"
        });
      });
    });
  }
}

[...document.querySelectorAll('.js-tabs')].forEach(item => new Tabs(item));