const burger = document.querySelector('#burger');
const nav = document.querySelector('#nav');
const navLinks = nav.querySelectorAll('.header__nav-link');
const body = document.body;

burger.addEventListener('click', () => {
  burger.classList.toggle('open');
  nav.classList.toggle('open');
  body.classList.toggle('overflow');
});

navLinks.forEach(link => {
  link.addEventListener('click', () => closeMenu())
});

const closeMenu = () => {
  burger.classList.remove('open');
  nav.classList.remove('open');
  body.classList.remove('overflow');
};
